import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.main,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  logoImgLg: {
    marginLeft: "15px",
    marginBottom: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoImgSm: {
    marginLeft: "15px",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: 0,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  formContainer: {
    width: 500,
    height: 500,
    position: "relative",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    padding: theme.spacing(2),
  },
  signupText: {
    marginBottom: theme.spacing(3),
    display: "flex",

  },
  signInText: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
  pendingText: {
    marginLeft: theme.spacing(4),
  },
  forgotPasswordHeading: {},
  forgotPasswordText: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  textField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [`& fieldset`]: {
      borderRadius: "10px",
    },
  },
  passwordField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [`& fieldset`]: {
      borderRadius: "10px",
      // borderColor: "red",
    },
  },
  textFieldInput: {
    width: "300px",
  },
  item: {
    marginBottom: theme.spacing(3),
  },
  input: {
    backgroundColor: "white",
    paddingLeft: "5px",
  },

  textDropDown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },

  formControl: {
    width: 300,
  },

  forgot: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  showHint: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorDiv: {
    marginBottom: theme.spacing(1),
    width: "300",
  },
  errorText: {
    color: "red",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    marginTop: theme.spacing(1),
    wordWrap: "break-word",
  },
  firstNextButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submitButtons: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-evenly",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#A9A9A9",
    margin: "0px 15px",
  },
}));
