import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    // position: "absolute",
    // backgroundColor: theme.palette.background.main,
    backgroundColor: "white",
    marginTop: theme.spacing(7),
    // height: "100%",
    // width: "100vw",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "white",
    // },
  },
  homeContainer: {
    // width: "100vw",
    // height: "80%",
    // position: "relative",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // margin: "auto",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",

    [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(7) + 2,
    }
  },
  container: {
    
    paddingTop: theme.spacing(7),
  },

  widgets: {
    display: "flex",
    // justifyContent: "space-around",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.between("sm","md")] : {
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    }
  },

  weatherWidget: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    }
  },

  // chart: {
  //   // marginBottom: theme.spacing(3),
  // },

  form: {
    padding: theme.spacing(2),
  },

  item: {
    marginBottom: theme.spacing(3),
  },

  submitButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorText: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#A9A9A9",
    margin: "0px 15px",
  },
}));
