import React, { useContext } from "react";
import { Card, Tooltip, Typography, useTheme } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import error from "../images/error.png";
import warning from "../images/warning.png";
import success from "../images/success.png";
import { deepOrange } from "@material-ui/core/colors";
import { AuthContext } from "../AuthContext";
import DevicesList from "./DevicesList";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  content: {
    display: "flex",
    // justifyContent: "space-around",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

}));

function HealthStatus() {
  const classes = useStyles();
  const { stateAllSeapods, stateMergedSeapodsData, stateAlertType, stateSidebar, statePodDevices } =
    useContext(AuthContext);
  const [sidebarState, setSidebarState] = stateSidebar;
  const [alertType, setAlertType] = stateAlertType;
  const [mergedSeapodsData, setMergedSeapodsData] = stateMergedSeapodsData;
  const [podDevices, setPodDevices] = statePodDevices;
  const theme = useTheme();

  function handleAvatarClick(id, name){
    setSidebarState("devices");
    setPodDevices({
      SeaPodID: id,
      SeaPodName: name
    });
    return <DevicesList />
  }

  return (
    <Card
      style={{ backgroundColor: theme.palette.cards.main, color: "white" }}
      variant='outlined'>
      <CardContent>
        <Typography variant='h6' component='h2'>
          Health Status
        </Typography>
        <div
          id='ho-status'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            // marginTop: theme.spacing(2),
          }}>
          {Object.keys(mergedSeapodsData).length !== 0 &&
            mergedSeapodsData.map((item) => (
              <Tooltip
                title={
                  item["SeaPodName"]
                }>
                <Avatar
                  alt={alertType.SeaPodID}
                  src={
                    Object.keys(alertType).length === 0 ||
                    (alertType.Type !== "error" && alertType.Type !== "warning")|| alertType.Type==="success"
                      ? success
                      : alertType.SeaPodID === item.SeaPodID &&
                        alertType.Type === "error"
                      ? error
                      : alertType.SeaPodID === item.SeaPodID &&
                        alertType.Type === "warning" ?
                        warning: item.SeaPodID!==alertType.SeaPodID && success
                  }
                  variant='rounded'
                  className={classes.large}
                  onClick={()=>handleAvatarClick(item.SeaPodID, item.SeaPodName)}
                style={{cursor: "pointer"}}
                />
                {/* </Badge> */}
              </Tooltip>
            ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default HealthStatus;
