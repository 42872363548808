import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { useStyles } from "./styles/styles";
import {
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function VerificationCode() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const { stateForm } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const history = useHistory();
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [codeSent, setCodeSent] = useState(false);

  const theme = useTheme();

  const sendVerificationCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute("email")
        .then(() => {
          setCodeSent(true);
          console.log("Verification code sent to email!");
        })
        .catch((e) => {
          console.log("failed with error", e);
          if (e.message) {
            setError(e.message);
          } else {
            setError(e);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const logOut = async () => {
    try {
      await Auth.signOut();
      setFormState("signedOut");
      history.push("/login");
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmitVerificationCode = async (e) => {
    e.preventDefault();
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode)
        .then(async () => {
          setError("Email has been verified!");
          logOut();
        })
        .catch((e) => {
          console.log("failed with error", e);
          setError(e.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getUserInfo() {
      try {
        const userInfo = await Auth.currentAuthenticatedUser().then((user) => {
          setUser(user);
          if (user.signInUserSession.idToken.payload.email_verified === false) {
            setFormState("emailNotVerified");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    getUserInfo();
  }, []);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <img
            src={require("../images/logo.png")}
            alt='logo'
            className={classes.logoImgLg}
            onClick={() => history.push("/")}
          />
          <img
            src={require("../images/logo-mobile.png")}
            alt='logo'
            className={classes.logoImgSm}
            onClick={() => history.push("/")}
          />
        </div>
      </Grid>
      {
        formState === "emailNotVerified" && (
          <Grid item xs={12} className={classes.formContainer}>
            <form
              onSubmit={onSubmitVerificationCode}
              className={classes.form}
              autoComplete='off'>
              {codeSent && (
                <div className={classes.item}>
                  <Typography variant='subtitle1'>
                    A verification code has been sent to your email.
                  </Typography>
                </div>
              )}
              <div className={classes.textField}>
                <TextField
                  id='verification-code'
                  label='Verfication Code'
                  variant='outlined'
                  type='number'
                  size='small'
                  required
                  className={classes.textFieldInput}
                  InputProps={{ className: classes.input }}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  // style={{ width: "100%" }}
                />
              </div>
              {error !== "" && (
                <Collapse in={open}>
                  <Alert
                    style={{ marginBottom: theme.spacing(2) }}
                    action={
                      <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                          setOpen(false);
                        }}>
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                    }
                    severity='error'>
                    {error}
                  </Alert>
                </Collapse>
              )}
              <div className={classes.submitButtons}>
                <div>
                  <Button
                    id='send-code'
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: "10px" }}
                    onClick={() => sendVerificationCode()}>
                    Send Code
                  </Button>
                </div>

                <div>
                  <Button
                    id='submit'
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: "10px" }}>
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    id='logout'
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: "10px" }}
                    onClick={() => logOut()}>
                    Log out
                  </Button>
                </div>
              </div>
            </form>
          </Grid>
        )
        // :(
        //   history.push("/")
        // )
      }
    </Grid>
  );
}

export default VerificationCode;
