import React, { useContext } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./styles/drawerItemsStyles";
import { AuthContext } from "../AuthContext";


function DrawerItems({ logout }) {
  const classes = useStyles();
  const { stateSidebar } = useContext(AuthContext);
  const [sidebarState, setSidebarState] = stateSidebar;
  return (
    <div className={classes.container}>
      <List component='nav' aria-label='sidebar'>
        <ListItem button onClick={()=>setSidebarState("dashboard")}>
          <ListItemText primary='Dashboard' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("manage_seapods")}>
          <ListItemText primary='Manage SeaPods' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("zone")}>
          <ListItemText primary='Zone' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("devices")}>
          <ListItemText primary='Devices' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("messages")}>
          <ListItemText primary='Messages' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("access_management")}>
          <ListItemText primary='Access Management' />
        </ListItem>
        
        <ListItem button onClick={()=>setSidebarState("users")}>
          <ListItemText primary='Users' />
        </ListItem>
        <ListItem button onClick={()=>setSidebarState("preferences")} 
        className={classes.mobileListItem}>
          <ListItemText primary='My Preferences' />
        </ListItem>
        <ListItem button onClick={logout} className={classes.mobileListItem}>
          <ListItemText primary='Logout'/>
        </ListItem>
      </List>
    </div>
  );
}

export default DrawerItems;
