import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AuthContext } from "../AuthContext";
import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import DrawerItems from "./DrawerItems";

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: "100vh",
  },
  closed: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    overflowX: "hidden",
  },
  opened: {
    width: "240px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const CustomDrawer = ({ logout }) => {
  const classes = useStyles();
  const { stateDrawer } = useContext(AuthContext);
  const [drawerState, setDrawerState] = stateDrawer;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      variant={isLargeScreen ? "permanent" : "temporary"}
      open={!isLargeScreen && drawerState ? true : false}
      onClose={() => setDrawerState(!drawerState)}
      classes={
        isLargeScreen
          ? {
              paper: clsx(classes.drawer, {
                [classes.opened]: true,
              }),
            }
          : {
              paper: clsx(classes.drawer, {
                [classes.closed]: !drawerState,
                [classes.opened]: drawerState,
              }),
            }
      }>
      <DrawerItems logout={logout} />
    </Drawer>
  );
};

export default CustomDrawer;
