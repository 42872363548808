import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [formState, setFormState] = useState("");
  const [email, setEmail] = useState("");  // will be used in forget password verification
  const [drawerState, setDrawerState] = useState(false);
  const [sidebarState, setSidebarState] = useState("dashboard");
  const [userDetails, setUserDetails] = useState({});
  const [allSeapods, setAllSeapods] = useState({});
  const [getSeapodsItems, setGetSeapodsItems] = useState([]);
  const [mergedSeapodsData, setMergedSeapodsData] = useState({});
  const [notificationCount, setNotificationCount] = useState(0);
  const [alertType, setAlertType] = useState({});
  const [podDevices,setPodDevices] = useState({});


  return (
    <AuthContext.Provider
      value={{
        stateForm: [formState, setFormState],
        stateDrawer: [drawerState, setDrawerState],
        stateSidebar: [sidebarState, setSidebarState],
        stateEmail: [email, setEmail],
        stateUser: [userDetails, setUserDetails],
        stateAllSeapods: [allSeapods, setAllSeapods],
        stateMergedSeapodsData: [mergedSeapodsData, setMergedSeapodsData],
        stateGetSeaPodItems: [getSeapodsItems, setGetSeapodsItems],
        stateNotificationCount: [notificationCount, setNotificationCount],
        stateAlertType: [alertType, setAlertType],
        statePodDevices: [podDevices, setPodDevices]
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
