import React, { useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import {
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./styles/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Login = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const { stateForm } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const theme = useTheme();

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const user = await Auth.signIn(username, password).then(() => {
        setFormState("signedIn");
        history.push("/");
      });
    } catch (error) {
      console.log(error);
      setError(error.message);
      if (error.code === "UserNotConfirmedException") {
        setFormState("pending");
        history.push("/pending");
      }
    }
  };
  useEffect(() => {
    async function isUserAuthenticated() {
      try {
        await Auth.currentAuthenticatedUser().then((user) => {
          setFormState("signedIn");
        });
      } catch (error) {
        console.log(error);
      }
    }
    isUserAuthenticated();
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <img
            src={require("../images/logo.png")}
            alt='logo'
            className={classes.logoImgLg}
            onClick={() => history.push("/")}
          />
          <img
            src={require("../images/logo-mobile.png")}
            alt='logo'
            className={classes.logoImgSm}
            onClick={() => history.push("/")}
          />
        </div>
      </Grid>
      {formState !== "signedIn" ? (
        <Grid item xs={12} className={classes.formContainer}>
          <form onSubmit={onSubmit} className={classes.form} autoComplete='off'>
            <div className={classes.signInText}>
              <Typography variant='h4' style={{ fontWeight: 500 }}>
                Sign In
              </Typography>
            </div>
            <div className={classes.textField}>
              <TextField
                id='email'
                label='Email'
                variant='outlined'
                size='small'
                type='email'
                required
                className={classes.textFieldInput}
                InputProps={{ className: classes.input }}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className={classes.textField}>
              <TextField
                id='password'
                label='Password'
                type='password'
                variant='outlined'
                size='small'
                required
                className={classes.textFieldInput}
                InputProps={{ className: classes.input }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className={classes.forgot}>
              <Typography>
                <Link
                  color='primary'
                  variant='subtitle1'
                  href='/forgotpassword'>
                  Forgot Password?
                </Link>
              </Typography>
            </div>
            {error !== "" && (
              <Collapse in={open}>
                <Alert
                  style={{ marginBottom: theme.spacing(2) }}
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  severity='error'>
                  {error}
                </Alert>
              </Collapse>
            )}
            <div className={classes.firstNextButton}>
              <Button
                id='submit'
                type='submit'
                variant='contained'
                color='primary'
                style={{ borderRadius: "10px" }}>
                Login
              </Button>
            </div>

            <div className={classes.item}>
              <Typography variant='subtitle1'>
                Don't have an account?{" "}
                <Link color='primary' variant='subtitle1' href='/signup'>
                  Create an account now.
                </Link>
              </Typography>
            </div>
          </form>
        </Grid>
      ) : (
        history.push("/")
      )}
      <Grid item xs={12} className={classes.footer}>
        <div className={classes.footerLeft}>
          <Typography variant='caption'>
            © Copyright OCEAN BUILDERS 2021
          </Typography>
        </div>
        <div className={classes.footerRight}>
          <Typography variant='caption'>
            Privacy | Terms | GRDP | Contact
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
