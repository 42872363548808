import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddNewDevice from "./AddNewDevice";
import { Alert } from "@material-ui/lab";

const columns = [
  { field: "id", headerName: "Device ID", width: 300, hide: true },
  {
    field: "SeaPodID",
    headerName: "SeaPod ID",
    width: 200,
    hide: true,
  },
  {
    field: "DeviceName",
    headerName: "Device Name",
    width: 150,
    editable: true,
  },
  {
    field: "Empty",
    headerName: "Empty",
    width: 100,
    editable: true,
  },
  {
    field: "Full",
    headerName: "Full",
    width: 100,
    editable: true,
  },
  {
    field: "LowerOptimumThreshold",
    headerName: "Lower Optimum Threshold",
    width: 100,
    editable: true,
  },
  {
    field: "UpperTrigger",
    headerName: "Upper Trigger",
    width: 100,
    editable: true,
  },
  {
    field: "LowerTrigger",
    headerName: "Lower Trigger",
    width: 100,
    editable: true,
  },
  {
    field: "UpperOptimumThreshold",
    headerName: "Upper Optimum Threshold",
    width: 100,
    editable: true,
  },
];

function objectPresent(o1, o2) {
  //   return JSON.stringify(o1) === JSON.stringify(o2);
  return Object.keys(o2).every((k1) =>
    Object.keys(o1).some((k2) => o2[k1] === o1[k2])
  );
}

const SeaPodDevices = ({ id }) => {
  const { stateUser, stateAllSeapods } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [devices, setDevices] = useState([]);
  const [updatedDevices, setUpdatedDevices] = useState({});
  const [paramsValue, setParamsValue] = useState({});
  const [showDatagrid, setShowDatagrid] = useState(true);
  const [error, setError] = useState({ text: "", severity: "" });
  const [open, setOpen] = useState(true);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [selection, setSelection] = useState({});
  const [dialog, setDialog] = useState(false);
  const [deviceName, setDeviceName] = useState("");

  const [dgRows, setdgRows] = useState([]);
  console.log(dgRows);

  //   console.log(updatedDevices);

  function addNewDevice() {
    setShowDatagrid(false);
  }

  async function updateDevices() {
    await axios
      .post(
        "https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod/seapod-device",
        updatedDevices,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      )
      .then((res) => {
        // setSuccess(true);
        setOpen(true);

        setError({ text: "Updated successfully!", severity: "success" });
        setShowSaveButton(false);
        const devicesObj = devices.find(
          (item) => item.DeviceID === paramsValue.id
        );
        Object.keys(devicesObj).forEach((item) => {
          if (item === paramsValue.field) {
            if (devicesObj[item] !== paramsValue.value) {
              devicesObj[item] = paramsValue.value;
            }
          }
        });
      })
      .catch((err) => {
        setError({ text: error, severity: "error" });
      });
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {showDeleteButton && (
          <Tooltip title='Delete Device'>
            <IconButton
              aria-label='delete device'
              size='large'
              color='primary'
              onClick={() => setDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {showSaveButton && (
          <Tooltip title='Save Device Details'>
            <IconButton
              aria-label='save device details'
              size='large'
              color='primary'
              onClick={() => updateDevices()}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
      </GridToolbarContainer>
    );
  }

  async function getSeaPodDevices(id) {
    try {
      // rows = [];
      setdgRows([]);
      await axios
        .get(
          `https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod/seapod-device?id=${id}`,
          {
            headers: {
              "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
            },
          }
        )
        .then((res) => {
          //   console.log(res);
          if (!res.data.devices) {
            // console.log("No devices found!");
            setOpen(true);

            setError({ text: "No devices found!", severity: "warning" });
          } else {
            if (Object.keys(res.data.devices.Items).length !== 0) {
              setDevices(res.data.devices.Items);
              res.data.devices.Items.map((item) =>
                // rows.push({
                //   id: item.DeviceID,
                //   SeaPodID: item.SeaPodID,
                //   DeviceName: item.DeviceName,
                //   Empty: item.Empty,
                //   Full: item.Full,
                //   LowerOptimumThreshold: item.LowerOptimumThreshold,
                //   UpperOptimumThreshold: item.UpperOptimumThreshold,
                //   UpperTrigger: item.UpperTrigger,
                //   LowerTrigger: item.LowerTrigger,
                // })

                setdgRows((dgRows)=>[
                  ...dgRows,
                  {
                    id: item.DeviceID,
                    SeaPodID: item.SeaPodID,
                    DeviceName: item.DeviceName,
                    Empty: item.Empty,
                    Full: item.Full,
                    LowerOptimumThreshold: item.LowerOptimumThreshold,
                    UpperOptimumThreshold: item.UpperOptimumThreshold,
                    UpperTrigger: item.UpperTrigger,
                    LowerTrigger: item.LowerTrigger,
                  },
                ])
              );
            }
          }
        });
    } catch (error) {
      //   console.log(error);
      setError({ text: error, severity: "error" });
    }
  }

  const deleteDevice = async () => {
    const selectedObj = {
      DeviceID: selection[0].id,
      SeaPodID: selection[0].SeaPodID,
    };

    if (deviceName === selection[0].DeviceName) {
      fetch(
        "https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod/seapod-device",
        {
          method: "DELETE",
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify(selectedObj),
        }
      )
        .then((res) => {
          setDialog(false);
          //   console.log(res);
          setError({ text: "Device has been deleted!", severity: "success" });
        })
        .catch((err) => console.log(err));
    } else {
      setOpen(true);
      //   setDialogError(true);
    }
  };

  useEffect(() => {
    setDevices({});
    setError({ text: "", severity: "" });
    setOpen(true);
    setShowDatagrid(true);
    getSeaPodDevices(id);
  }, [id]);

  return (
    <>
      {error.text !== "" && (
        <Collapse in={open}>
          <Alert
            variant='filled'
            severity={error.severity}
            style={{ marginBottom: "5px" }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            {error.text}
          </Alert>
        </Collapse>
      )}
      {Object.keys(selection).length > 0 && (
        <Dialog open={dialog} onClose={() => setDialog(false)}>
          <DialogTitle>Delete Device</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete device? Enter the Device Name:{" "}
              <b>
                <em>{selection[0].DeviceName}</em>
              </b>
              . This action is not reversible.
            </DialogContentText>
            <TextField
              margin='dense'
              // id='name'
              label='Device Name'
              required
              fullWidth
              onChange={(e) => setDeviceName(e.target.value)}
              variant='outlined'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
            <Button onClick={() => deleteDevice()}>Delete</Button>
          </DialogActions>
          {/* {dialogError && (
            <div>
              <Collapse in={open}>
                <Alert
                  style={{
                    marginBottom: theme.spacing(2),
                    marginTop: theme.spacing(2),
                  }}
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  severity='error'>
                  Please enter correct SeaPod Name!
                </Alert>
              </Collapse>
            </div>
          )} */}
        </Dialog>
      )}
      {showDatagrid && (
        <>
          <div style={{ width: "100%" }} id='data-grid'>
            {Object.keys(dgRows).length !== 0 && (
              <DataGrid
                rows={dgRows}
                columns={columns}
                autoHeight
                disableSelectionOnClick
                checkboxSelection
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = dgRows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  setSelection(selectedRowData);
                  setShowDeleteButton(false);
                  if (Object.keys(selectedRowData).length !== 0) {
                    setShowDeleteButton(true);
                  }
                }}
                onCellEditCommit={(params, event) => {
                  setShowSaveButton(false);
                  setParamsValue(params);
                  const searchObj = devices.find(
                    (item) => item.DeviceID === params.id
                  );
                  console.log(searchObj);
                  setUpdatedDevices(searchObj);
                  Object.keys(searchObj).forEach((item) => {
                    if (item === params.field) {
                      if (searchObj[item] !== params.value) {
                        searchObj[item] = params.value;
                      }
                    }
                  });
                  console.log(objectPresent(devices, updatedDevices));
                  if (!objectPresent(devices, updatedDevices)) {
                    setShowSaveButton(true);
                  }
                }}
              />
            )}
          </div>
          <div>
            <Button
              variant='contained'
              color='primary'
              // className={classes.button}
              startIcon={<AddBoxIcon />}
              style={{ marginTop: "10px" }}
              onClick={() => addNewDevice()}>
              Add New Device
            </Button>
          </div>
        </>
      )}
      {showDatagrid === false && (
        <AddNewDevice id={id} setOpen={setOpen} setError={setError} />
      )}
    </>
  );
};

export default SeaPodDevices;
