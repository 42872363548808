import React, { useContext, useState } from "react";
// import "./ForgotPassword.css";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../AuthContext";
import {
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./styles/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function ForgotPassword() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { stateForm, stateEmail } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const [username, setUsername] = stateEmail;
  const history = useHistory();
  const theme = useTheme();
  const [error, setError] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPassword(username)
        .then((data) => {
          console.log(data);
          setFormState("forgotPasswordVerification");
          history.push("/forgotpasswordverification");
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <img
            src={require("../images/logo.png")}
            alt='logo'
            className={classes.logoImgLg}
            onClick={() => history.push("/")}
          />
          <img
            src={require("../images/logo-mobile.png")}
            alt='logo'
            className={classes.logoImgSm}
            onClick={() => history.push("/")}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.formContainer}>
        <form onSubmit={submitForm} className={classes.form}>
          {/* <div className='forgotPasswordBox'>
            <label className='emailLabel'>Email</label>
            <input
              name='username'
              placeholder='Enter email here'
              onChange={(e) => setUsername(e.target.value)}
              className='forgotPasswordInput'></input>
          </div> */}
          <div className={classes.item}>
            <Typography variant='h5' style={{ fontWeight: 500 }}>
              Forgot your password?
            </Typography>
            <Typography variant='body1' className={classes.forgotPasswordText}>
              Please enter the email address associated with your account and
              we'll send you a password reset code.
            </Typography>
          </div>
          <div className={classes.textField}>
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              size='small'
              required
              type='email'
              className={classes.textFieldInput}
              InputProps={{ className: classes.input }}
              onChange={(e) => setUsername(e.target.value)}
              // style={{ width: "100%" }}
            />
          </div>
          {error !== "" && (
            <Collapse in={open}>
              <Alert
                style={{ marginBottom: theme.spacing(2) }}
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      setOpen(false);
                    }}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                severity='error'>
                {error}
              </Alert>
            </Collapse>
          )}
          <div className={classes.firstNextButton}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              style={{ borderRadius: "10px" }}>
              Confirm
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
