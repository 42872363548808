import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import config from "../config.json";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 300,
    // width: "auto",
    backgroundColor: theme.palette.cards.main,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  media: {
    height: "100px",
    width: "100px",
    // paddingTop: '56.25%', // 16:9
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function WeatherWidget() {
  const classes = useStyles();
  const [weather, setWeather] = useState({
    tempK: 0,
    tempC: 0,
    tempF: 0,
    feelsLike: 0,
    feelsLikeC: 0,
    feelsLikeF: 0,
    region: "",
    countryCode: "",
    weatherDesc: "",
    icon: "",
  });
  const API_URL = "https://api.openweathermap.org/data/2.5/weather";
  const API_KEY = config.OpenWeatherMap.API_KEY;
  const CITY_NAME = "Colon";

  // console.log(weather);

  const KtoC = (val) => {
    return Math.round(val - 273.15);
  };

  const CtoF = (val) => {
    return Math.round(val * 1.8 + 32);
  };

  useEffect(() => {
    async function getWeather() {
      const FULL_API_URL = `${API_URL}?q=${CITY_NAME}&appid=${API_KEY}`;
      try {
        await fetch(FULL_API_URL)
          .then((res) => res.json())
          .then((json) => {
            // console.log(json);
            var C = KtoC(json["main"]["temp"]);
            var F = CtoF(C);
            var feelsLike_C = KtoC(json["main"]["feels_like"]);
            var feelsLike_F = CtoF(feelsLike_C);
            setWeather((prev) => {
              return {
                ...prev,
                tempK: json["main"]["temp"],
                tempC: C,
                tempF: F,
                feelsLikeK: json["main"]["feels_like"],
                feelsLikeC: feelsLike_C,
                feelsLikeF: feelsLike_F,
                region: json["name"],
                countryCode: json["sys"]["country"],
                weatherDesc: json["weather"][0]["description"],
                icon: json["weather"][0]["icon"],
              };
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
    getWeather();
  }, []);

  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent className={classes.content}>
        <div>
          <Typography id='temp' variant='h6' component='h2'>
            {weather["tempC"]}°C
          </Typography>
          <Typography id='feels-like' className={classes.title} gutterBottom>
            Feels like {weather["feelsLikeC"]}°C
          </Typography>
          <Typography id='region' className={classes.title} gutterBottom>
            {weather["region"]}, {weather["countryCode"]}
          </Typography>
        </div>
        <CardMedia
          id='weather-icon'
          className={classes.media}
          image={`http://openweathermap.org/img/wn/${weather["icon"]}@2x.png`}
        />
      </CardContent>
    </Card>
  );
}

export default WeatherWidget;
