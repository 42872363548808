import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../AuthContext";
import { Button, Grid, TextField, Typography, useTheme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./styles/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function ForgotPasswordVerification() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { stateForm, stateEmail } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const [username, setUsername] = stateEmail;
  const history = useHistory();
  const theme = useTheme();
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
        .then((data) => {
          console.log(data);
          setFormState("passwordChanged");
          history.push("passwordchanged");
        })
        .catch((err) => setError(err.message));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <img
            src={require("../images/logo.png")}
            alt='logo'
            className={classes.logoImgLg}
            onClick={() => history.push("/")}
          />
          <img
            src={require("../images/logo-mobile.png")}
            alt='logo'
            className={classes.logoImgSm}
            onClick={() => history.push("/")}
          />
        </div>
      </Grid>
      {formState === "forgotPasswordVerification" ? (
        <Grid item xs={12} className={classes.formContainer}>
          <form onSubmit={submitForm} className={classes.form}>
            <div className={classes.item}>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                Set new password
              </Typography>
              <Typography
                variant='body1'
                className={classes.forgotPasswordText}>
                Please enter the verification code sent to your email address
                below, your email address and a new password.
              </Typography>
            </div>
            <div className={classes.textField}>
              <TextField
                id='outlined-basic'
                label='Verification Code'
                variant='outlined'
                size='small'
                required
                className={classes.textFieldInput}
                InputProps={{ className: classes.input }}
                onChange={(event) => {
                  setVerificationCode(event.target.value);
                }}
                // style={{ width: "100%" }}
              />
            </div>

            <div className={classes.textField}>
              <TextField
                id='outlined-basic'
                label='New Password'
                type='password'
                variant='outlined'
                size='small'
                required
                className={classes.textFieldInput}
                InputProps={{ className: classes.input }}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                // style={{ width: "100%" }}
              />
            </div>
            {error !== "" && (
              <Collapse in={open}>
                <Alert
                  style={{ marginBottom: theme.spacing(2) }}
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  severity='error'>
                  {error}
                </Alert>
              </Collapse>
            )}
            <div className={classes.firstNextButton}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                style={{ borderRadius: "10px" }}>
                Confirm
              </Button>
            </div>
          </form>
        </Grid>
      ) : (
        history.push("/")
      )}
    </Grid>
  );
}

export default ForgotPasswordVerification;
