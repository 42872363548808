import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import {
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles } from "./styles/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { CountryRegionData } from "react-country-region-selector";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Signup = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [seapodZone, setSeapodZone] = useState("");
  const [seapodName, setSeapodName] = useState("");
  const [isAdmin, setIsAdmin] = useState("false");

  const theme = useTheme();
  const [error, setError] = useState("");
  const [showHint, setShowHint] = useState(false);
  const history = useHistory();
  const { stateForm } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const [next1, setNext1] = useState(false);
  const [next2, setNext2] = useState(false);

  const getRegions = (country) => {
    if (!country) {
      return [];
    }
    return country[2].split("|").map((regionPair) => {
      let [regionName, regionShortCode = null] = regionPair.split("~");
      return regionName;
    });
  };

  const usernameAvailable = async (email_username) => {
    try {
      const res = await Auth.confirmSignUp(email_username, "000000", {
        // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
        forceAliasCreation: false,
      });
      // this should always throw an error of some kind, but if for some reason this succeeds then the user probably exists.
      // Disable PreventUserExistenceErrors from Cognito web client
      return false;
    } catch (err) {
      console.log(err);
      switch (err.code) {
        case "UserNotFoundException":
          return true;
        case "NotAuthorizedException":
          return false;
        case "AliasExistsException":
          // Email alias already exists
          return false;
        case "CodeMismatchException":
          return false;
        case "ExpiredCodeException":
          return false;
        default:
          return false;
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);
    setError("");
    if (next1 === false && username && firstName && lastName) {
      const email_available = await usernameAvailable(username);
      if (email_available && validateEmail()) {
        setNext1(true);
      } else if (!email_available) {
        setError("Email already exists!");
      } else if (!validateEmail()) {
        setError("Please enter a valid email address");
      }
      return;
    }
    if (next2 === false && address && city && state && country && zipCode) {
      setNext2(true);
      return;
    }
    if (next2 === true) {
      if (!validatePassword()) {
        try {
          const signUpResponse = await Auth.signUp({
            username,
            password,

            attributes: {
              name: firstName,
              family_name: lastName,
              address,
              phone_number: phoneNumber,
              "custom:city": city,
              "custom:state": state,
              "custom:country": country[0],
              "custom:zipcode": zipCode,
              "custom:seapod_zone": seapodZone,
              "custom:isAdmin": isAdmin,
            },
          });
          setFormState("pending");
          history.push("/pending");
          // console.log(signUpResponse);
        } catch (error) {
          console.log(error);
          setError(error.message);
        }
      }
    }
  };

  const validateEmail = () => {
    if (typeof username !== "undefined") {
      var pattern = new RegExp(
        // For production
        // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        // For development
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      );
      if (!pattern.test(username)) {
        return false;
      }
      return true;
    }
  };

  const validatePassword = () => {
    let invalid = false;
    if (typeof password !== undefined && typeof confirmPassword !== undefined) {
      if (confirmPassword !== password) {
        invalid = true;
        setError("Passwords don't match");
      }
    }
    return invalid;
  };

  useEffect(() => {
    async function isUserAuthenticated() {
      try {
        await Auth.currentAuthenticatedUser().then((user) =>
          setFormState("signedIn")
        );
      } catch (error) {
        console.log(error);
      }
    }
    isUserAuthenticated();
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <img
            src={require("../images/logo.png")}
            alt='logo'
            className={classes.logoImgLg}
            onClick={() => history.push("/")}
          />
          <img
            src={require("../images/logo-mobile.png")}
            alt='logo'
            className={classes.logoImgSm}
            onClick={() => history.push("/")}
          />
        </div>
      </Grid>
      {formState !== "signedIn" ? (
        <Grid item xs={12} className={classes.formContainer}>
          <form onSubmit={onSubmit} className={classes.form} autoComplete='off'>
            <div className={classes.signupText}>
              <Typography variant='h4' style={{ fontWeight: 500 }}>
                Sign up
              </Typography>
            </div>
            {next1 === false && (
              <>
                <div className={classes.textField}>
                  <TextField
                    id='email'
                    label='Email'
                    variant='outlined'
                    size='small'
                    required
                    type='email'
                    defaultValue={username}
                    className={classes.textFieldInput}
                    InputProps={{ className: classes.input }}
                    onChange={(e) => setUsername(e.target.value)}
                    // style={{ width: "100%" }}
                  />
                </div>
                <div className={classes.textField}>
                  <TextField
                    id='first-name'
                    label='First Name'
                    variant='outlined'
                    size='small'
                    required
                    defaultValue={firstName}
                    className={classes.textFieldInput}
                    InputProps={{ className: classes.input }}
                    onChange={(e) => setFirstName(e.target.value)}
                    // style={{ width: "100%" }}
                  />
                </div>
                <div className={classes.textField}>
                  <TextField
                    id='last-name'
                    label='Last Name'
                    variant='outlined'
                    size='small'
                    required
                    defaultValue={lastName}
                    className={classes.textFieldInput}
                    InputProps={{ className: classes.input }}
                    onChange={(e) => setLastName(e.target.value)}
                    // style={{ width: "100%" }}
                  />
                </div>
                {error !== "" && (
                  <div style={{ maxWidth: 300 }}>
                    <Collapse in={open}>
                      <Alert
                        style={{
                          marginBottom: theme.spacing(2),
                          maxWidth: 300,
                        }}
                        action={
                          <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={() => {
                              setOpen(false);
                            }}>
                            <CloseIcon fontSize='inherit' />
                          </IconButton>
                        }
                        severity='error'>
                        {error}
                      </Alert>
                    </Collapse>
                  </div>
                )}
                <div className={classes.firstNextButton}>
                  <Button
                    id='next1'
                    variant='contained'
                    color='primary'
                    style={{ borderRadius: "10px" }}
                    type='submit'>
                    Next
                  </Button>
                </div>
              </>
            )}
            {next1 && (
              <>
                {next2 === false && (
                  <>
                    <div className={classes.textField}>
                      <TextField
                        id='address'
                        label='Address'
                        variant='outlined'
                        multiline='true'
                        maxRows='3'
                        size='small'
                        required
                        defaultValue={address}
                        className={classes.textFieldInput}
                        InputProps={{ className: classes.input }}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className={classes.textField}>
                      <TextField
                        id='city'
                        label='City'
                        variant='outlined'
                        size='small'
                        required
                        defaultValue={city}
                        className={classes.textFieldInput}
                        InputProps={{ className: classes.input }}
                        onChange={(e) => setCity(e.target.value)}
                        // style={{ width: "100%" }}
                      />
                    </div>

                    <div className={classes.textDropDown}>
                      <FormControl
                        variant='outlined'
                        className={classes.formControl}>
                        <InputLabel>Country</InputLabel>
                        <Select
                          style={{
                            borderRadius: 10,
                            backgroundColor: "white",
                          }}
                          required
                          id='country'
                          defaultValue={country}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          label='Country'>
                          {CountryRegionData.map((option, index) => (
                            <MenuItem key={option[0]} value={option}>
                              {option[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={classes.textDropDown}>
                      <FormControl
                        variant='outlined'
                        className={classes.formControl}>
                        <InputLabel>State</InputLabel>
                        <Select
                          id='state'
                          style={{
                            borderRadius: 10,
                            backgroundColor: "white",
                          }}
                          required
                          defaultValue={state}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          label='State'>
                          {getRegions(country).map((option, index) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={classes.textField}>
                      <TextField
                        id='zip-code'
                        label='Zip Code'
                        variant='outlined'
                        size='small'
                        required
                        defaultValue={zipCode}
                        className={classes.textFieldInput}
                        InputProps={{ className: classes.input }}
                        onChange={(e) => setZipCode(e.target.value)}
                        // style={{ width: "100%" }}
                      />
                    </div>
                    {error !== "" && (
                      <div style={{ maxWidth: 300 }}>
                        <Collapse in={open}>
                          <Alert
                            style={{
                              marginBottom: theme.spacing(2),
                              maxWidth: 300,
                            }}
                            action={
                              <IconButton
                                aria-label='close'
                                color='inherit'
                                size='small'
                                onClick={() => {
                                  setOpen(false);
                                }}>
                                <CloseIcon fontSize='inherit' />
                              </IconButton>
                            }
                            severity='error'>
                            {error}
                          </Alert>
                        </Collapse>
                      </div>
                    )}
                    <div className={classes.submitButtons}>
                      <div className={classes.buttonSize}>
                        <Button
                          id='back1'
                          variant='contained'
                          color='primary'
                          style={{ borderRadius: "10px" }}
                          onClick={() => setNext1(false)}>
                          Back
                        </Button>
                      </div>
                      <div className={classes.buttonSize}>
                        <Button
                          id='next2'
                          variant='contained'
                          color='primary'
                          style={{ borderRadius: "10px" }}
                          type='submit'>
                          Next
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {next2 && (
                  <>
                    <div>
                      <PhoneInput
                        data-test-id='phone'
                        inputStyle={{
                          borderRadius: "10px",
                        }}
                        containerStyle={{
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",
                          marginBottom: theme.spacing(3),
                        }}
                        inputProps={{ required: true, prefix: true }}
                        country={"us"}
                        defaultValue={phoneNumber}
                        value={phoneNumber}
                        onChange={(phone) => {
                          setPhoneNumber("+" + phone);
                        }}
                      />
                    </div>
                    <div className={classes.textDropDown}>
                      <FormControl
                        variant='outlined'
                        className={classes.formControl}>
                        <InputLabel>SeaPod Zone</InputLabel>
                        <Select
                          id='seapod-zone'
                          style={{
                            borderRadius: 10,
                            backgroundColor: "white",
                          }}
                          required
                          defaultValue={seapodZone}
                          value={seapodZone}
                          onChange={(e) => setSeapodZone(e.target.value)}
                          label='SeaPod Zone'>
                          <MenuItem value={"Panama"}>Panama</MenuItem>
                          <MenuItem value={"Dubai"}>Dubai</MenuItem>
                          <MenuItem value={"Miami"}>Miami</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.passwordField}>
                      <TextField
                        id='password1'
                        label='Password'
                        type='password'
                        variant='outlined'
                        size='small'
                        required
                        defaultValue={password}
                        className={classes.textFieldInput}
                        error={password !== confirmPassword}
                        InputProps={{ className: classes.input }}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />
                    </div>
                    <div
                      className={classes.passwordField}
                      style={{ marginBottom: 0 }}>
                      <TextField
                        id='password2'
                        label='Confirm Password'
                        type='password'
                        variant='outlined'
                        size='small'
                        required
                        defaultValue={confirmPassword}
                        error={password !== confirmPassword}
                        className={classes.textFieldInput}
                        InputProps={{ className: classes.input }}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                    </div>
                    <div
                      className={classes.textDropDown}
                      style={{ marginBottom: theme.spacing(1) }}>
                      <PasswordStrengthMeter password={password} />
                    </div>
                    <div style={{ marginBottom: theme.spacing(1) }}>
                      <Typography
                        variant='subtitle2'
                        onClick={() => {
                          if (showHint !== true) {
                            setShowHint(true);
                          } else {
                            setShowHint(false);
                          }
                        }}
                        className={classes.showHint}>
                        Show Password Policy?
                      </Typography>
                      {showHint && (
                        <Typography
                          variant='subtitle2'
                          className={classes.showHint}>
                          Password should contain numbers, special <br />
                          characters, upper and lower case letters.
                          <br />
                          Minimum password length is 8 characters.
                        </Typography>
                      )}
                    </div>
                    {error !== "" && (
                      <div style={{ maxWidth: 300 }}>
                        <Collapse in={open}>
                          <Alert
                            style={{ marginBottom: theme.spacing(2) }}
                            action={
                              <IconButton
                                aria-label='close'
                                color='inherit'
                                size='small'
                                onClick={() => {
                                  setOpen(false);
                                }}>
                                <CloseIcon fontSize='inherit' />
                              </IconButton>
                            }
                            severity='error'>
                            {error}
                          </Alert>
                        </Collapse>
                      </div>
                    )}
                    <div>
                      <div className={classes.submitButtons}>
                        <div className={classes.buttonSize}>
                          <Button
                            id='back2'
                            variant='contained'
                            color='primary'
                            style={{ borderRadius: "10px" }}
                            onClick={() => setNext2(false)}>
                            Back
                          </Button>
                        </div>
                        <div className={classes.buttonSize}>
                          <Button
                            id='submit'
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ borderRadius: "10px" }}>
                            Sign up
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div className={classes.textDropDown} style={{ marginBottom: 0 }}>
              <Typography variant='subtitle1'>
                Already have an account?{" "}
                <Link color='primary' variant='subtitle1' href='/login'>
                  Sign In
                </Link>
              </Typography>
            </div>
          </form>
        </Grid>
      ) : (
        history.push("/")
      )}
      <Grid item xs={12} className={classes.footer}>
        <div className={classes.footerLeft}>
          <Typography variant='caption'>
            © Copyright OCEAN BUILDERS 2021
          </Typography>
        </div>
        <div className={classes.footerRight}>
          <Typography variant='caption'>
            Privacy | Terms | GRDP | Contact
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Signup;
