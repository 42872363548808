import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SeaPodDevices from "./SeaPodDevices";
import { AuthContext } from "../AuthContext";

const DevicesList = () => {
  const { stateAllSeapods,statePodDevices } = useContext(AuthContext);
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [seapodID, setSeapodID] = useState("");
  const [seapodName, setSeapodName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [podDevices, setPodDevices] = statePodDevices;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id, name) => {
    setAnchorEl(null);
    setSeapodID(id);
    setSeapodName(name);
  };

useEffect(()=>{
  if(Object.keys(podDevices).length !==0){
    setSeapodID(podDevices.SeaPodID);
    setSeapodName(podDevices.SeaPodName);
  }
}, [])

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        style={{marginBottom: "10px"}}
        >
        {seapodName !== "" ? seapodName : "Select Pod"}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        {allSeapods.map((seapod) => (
          <MenuItem
            onClick={() => handleClose(seapod.SeaPodID, seapod.SeaPodName)}>
            {seapod.SeaPodName}
          </MenuItem>
        ))}
      </Menu>
      {seapodID !== "" && <SeaPodDevices id={seapodID} />}
    </div>
  );
};

export default DevicesList;
