import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import CloseIcon from "@material-ui/icons/Close";

const Preferences = () => {
  const { stateUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;
  const [open, setOpen] = useState(true);
  const [adminPrefs, setAdminPrefs] = useState({});
  const [checked, setChecked] = useState({
    email: false,
    sms: false,
  });
  const [res, setRes] = useState({
    msg: "",
    severity: "",
  });

  console.log(adminPrefs);

  function handleCheckbox(event) {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setOpen(true);
    let adminPreferences = {
      AdminID: userDetails.username,
      NotificationEmail: userDetails.signInUserSession.idToken.payload.email,
    };
    if (checked.email) {
      adminPreferences["UnsubEmail"] = false;
    } else {
      adminPreferences["UnsubEmail"] = true;
    }
    if (
      Object.keys(userDetails.signInUserSession.idToken.payload).includes(
        "phone_number"
      )
    ) {
      adminPreferences["NotificationSMS"] =
        userDetails.signInUserSession.idToken.payload.phone_number;
    }
    if (checked.sms) {
      adminPreferences["UnsubSMS"] = false;
    } else {
      adminPreferences["UnsubSMS"] = true;
    }

    try {
      await axios.put(
        "https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod/admin-preferences",
        adminPreferences,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      );
      setRes({
        msg: "Your preferences have been updated!",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function getAdminPreferences() {
    setOpen(true);
    try {
      const res = await axios.get(
        `https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod/admin-preferences?admin_id=${userDetails.username}`,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (res.data.admin !== undefined) {
        setAdminPrefs(res.data.admin);
        if (
          res.data.admin.UnsubEmail !== false &&
          res.data.admin.UnsubSMS !== false
        ) {
          console.log("1")
          setChecked({ email: false, sms: false });
        }else if (
          res.data.admin.UnsubSMS !== false &&
          res.data.admin.UnsubEmail !== true
        ) {
          console.log("2")
          setChecked({ email: true, sms: false });
        } else if (
          res.data.admin.UnsubSMS !== true &&
          res.data.admin.UnsubEmail !== false
        ) {
          console.log("3")
          setChecked({ email: false, sms: true });
        } else if (
          res.data.admin.UnsubSMS !== true &&
          res.data.admin.UnsubEmail !== true
        ) {
          console.log("4")
          setChecked({ email: true, sms: true });
        }
      } else {
        setAdminPrefs({});
      }
    } catch (error) {
      console.log(error);
      //   setRes({
      //     msg: error,
      //     severity: "error",
      //   });
    }
  }
  useEffect(() => {
    getAdminPreferences();
  }, []);

  return (
    <div>
      {res.msg !== "" && (
        <Collapse in={open}>
          <Alert
            variant='filled'
            severity={res.severity}
            style={{ marginBottom: "5px" }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            {res.msg}
          </Alert>
        </Collapse>
      )}
      <Typography variant='subtitle1'>Get alert notifications via</Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked.email}
              onChange={handleCheckbox}
              name='email'
            />
          }
          label='Email'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked.sms}
              onChange={handleCheckbox}
              name='sms'
            />
          }
          label='Text/SMS'
        />
      </FormGroup>
      {checked.email && (
        <Typography variant='subtitle1'>
          You will receive notifications on your{" "}
          {userDetails.signInUserSession.idToken.payload.email}. Please check
          email and confirm your subscription.
        </Typography>
      )}
      {checked.sms &&
      Object.keys(userDetails.signInUserSession.idToken.payload).includes(
        "phone_number"
      ) ? (
        <Typography variant='subtitle1'>
          You will receive notifications via SMS/TEXT on your{" "}
          {userDetails.signInUserSession.idToken.payload.phone_number}. It will
          have additional cost to company.
        </Typography>
      ) : (
        checked.sms &&
        !Object.keys(userDetails.signInUserSession.idToken.payload).includes(
          "phone_number"
        ) && (
          <Typography variant='subtitle1'>
            You won't be able to receive alerts via SMS/TEXT. Phone number was
            not provided during registration.
          </Typography>
        )
      )}
      <Button
        id='submit'
        variant='contained'
        color='primary'
        style={{ borderRadius: "10px" }}
        type='submit'
        onClick={handleSubmit}>
        Save
      </Button>
    </div>
  );
};

export default Preferences;
