import React from "react";
// import "./App.css";
import Home from "./components/Home";
import Signup from "./components/Signup";
import Login from "./components/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { AuthProvider } from "./AuthContext";
import ForgotPassword from "./components/ForgotPassword";
import ForgotPasswordVerification from "./components/ForgotPasswordVerification";
import PasswordChanged from "./components/PasswordChanged";
import Pending from "./components/Pending";
import VerificationCode from "./components/VerificationCode";

const App = () => {

  return (
    <AuthProvider>
      <Router history={history}>
        <Switch>
          <Route exact path='/'>
            <Home  />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route path='/signup'>
            <Signup  />
          </Route>
          <Route path='/pending'>
            <Pending  />
          </Route>
          <Route path='/forgotpassword'>
            <ForgotPassword  />
          </Route>
          <Route path='/forgotpasswordverification'>
            <ForgotPasswordVerification  />
          </Route>
          <Route path='/passwordchanged'>
            <PasswordChanged  />
          </Route>
          <Route path='/sendconfirmationcode'>
            <VerificationCode  />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default App;
