import React, { useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles/styles";

function Pending() {
  const classes = useStyles();
  const history = useHistory();
  const { stateForm } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  console.log("form state in confirmtion:" + formState);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setFormState("signedIn"))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
      <div className={classes.logo}>
            <img
              src={require("../images/logo.png")}
              alt='logo'
              className={classes.logoImgLg}
                onClick={() => history.push("/")}
            />
            <img
              src={require("../images/logo-mobile.png")}
              alt='logo'
              className={classes.logoImgSm}
                onClick={() => history.push("/")}
            />
          </div>
      </Grid>
      {formState === "pending" ? (
        <Grid item xs={12} className={classes.formContainer}>
          <div className={classes.item}>
            <Typography variant='h6' style={{fontWeight:500}}>
              Your sign up request is pending for approval by admin.
            </Typography>
          </div>
        </Grid>
      ) : (
        history.push("/")
      )}
    </Grid>
  );
}

export default Pending;
