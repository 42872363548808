import { Card, Typography } from "@material-ui/core";
import React from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function WaterTankChart({ mergedData }) {
  return (
    <>
      <Typography variant='h6'>Water capacity of Seapods</Typography>

      <ResponsiveContainer width='100%' height='100%'>
        <BarChart width={500} height={200} data={mergedData} id='water-tank'>
          <Tooltip />
          <Legend />
          <Bar
            dataKey='Watertank1'
            fill='#353846'
            barSize={30}
            formatter={(label) => label + "%"}
          />
          <Bar
            dataKey='Watertank2'
            fill='#43A5B5'
            barSize={30}
            formatter={(label) => label + "%"}
          />
          <Bar
            dataKey='Watertank3'
            fill='#D1CBBC'
            barSize={30}
            formatter={(label) => label + "%"}
          />
          <XAxis dataKey='SeaPodName' />
          <YAxis
            label={{ value: "Percent", angle: -90, position: "insideLeft" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default WaterTankChart;
