import { Typography } from "@material-ui/core";
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function PowerProduced({ mergedData }) {
  return (
    <>
      <Typography variant='h6'>Power Production by Solar System</Typography>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={500}
          height={200}
          data={mergedData}
          id='power-produced'>
          <Tooltip formatter={(label) => label + "KWh"} />
          <Legend />
          <Bar dataKey='SolarPowerProduction' barSize={30}>
            {mergedData.map((entry, index) => (
              <Cell
                cursor='pointer'
                fill={
                  entry["SolarPowerProduction"] >= 8
                    ? "green"
                    : (entry["SolarPowerProduction"] >= 5) &
                      (entry["SolarPowerProduction"] < 8)
                    ? "red"
                    : entry["SolarPowerProduction"] < 5
                    ? "gray"
                    : "none"
                }
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <XAxis dataKey='SeaPodName' />
          <YAxis label={{ value: "KWh", angle: -90, position: "insideLeft" }} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default PowerProduced;
